<template>
  <div id="transmission-rate">
    <div class="health">
      <app-tree @select="onSelect"></app-tree>
      <div class="content">
        <h3>
          <span v-if="$store.state.parentData.name"
            >{{ $store.state.parentData.name }}-</span
          ><span v-if="$store.state.selectedData.name">{{
            $store.state.selectedData.name
          }}</span>
        </h3>
        <div class="search-shell">
          <p class="title">数据传输率</p>
        </div>
        <div class="search-btn">
          <div class="select-all">
            <div class="label">企业或基站名称</div>
            <a-input
              @change="onChangeVal"
              :value="JZMC"
              style="width: 170px"
              placeholder="请输入企业或基站名称"
            />
          </div>
          <div class="select-all">
            <div class="label" style="width: 60px">时间段</div>
            <a-range-picker
              ref="dateName"
              style="width: 240px"
              :default-value="[
                moment($store.state.yesterday),
                moment($store.state.JSSJ),
              ]"
              show-time
              :format="dateFormat"
              @change="onChangeDate"
            />
          </div>
          <a-button @click="onSearch" class="abnormal-btn" type="primary">
            查询
          </a-button>
          <a-button class="abnormal-btn" @click="onExport" type="primary">
            导出
          </a-button>
        </div>
        <div class="table-shell">
          <a-table
            :scroll="{ y: 490 }"
            :loading="loading"
            :customRow="customRow"
            :columns="columns"
            size="small"
            :row-key="(record) => record.station_bh"
            :data-source="data"
            bordered
            :pagination="false"
          >
          </a-table>
          <div style="display: flex; padding-bottom: 10px">
            <div style="flex: 1"></div>
            <a-pagination
              show-quick-jumper
              :page-size="pageSize"
              v-model="current"
              :total="total"
              :show-total="
                (total, range) =>
                  `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`
              "
              @change="onChange"
            />
            <div class="all-total" style="display: none">共{{ total }}条</div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      width="760px"
      centered
      v-model="visible"
      :title="datamate.EnterpriseallMC"
      ok-text="确认"
      cancel-text="取消"
      @ok="hideModal"
    >
      <centered-table v-bind:datamate="datamate"></centered-table>
    </a-modal>
  </div>
</template>

<script>
import AppTree from "@/components/AppTree.vue";
import BaseSelect from "../../components/BaseSelect.vue";
import CenteredTable from "../../components/CenteredTable.vue";
import { http } from "../../utils/Http.js";
import moment from "moment";

const columns = [
  {
    title: "企业名称",
    dataIndex: "EnterpriseMC",
    key: "EnterpriseBH",
    align: "center",
  },
  {
    title: "基站名称",
    dataIndex: "station_name",
    key: "station_bh",
    align: "center",
  },
  {
    title: "基站传输率(%)",
    dataIndex: "Tran_Rate",
    key: "Tran_Rate",
    align: "center",
  },
];

export default {
  data() {
    return {
      data: [],
      columns,
      current: 1,
      width: "120px",
      dateFormat: "YYYY-MM-DD",
      pageSize: 20,
      total: 0,

      EnterpriseBH: "",
      JZMC: "", //基站名称或企业名称
      KSSJ: this.$store.state.yesterday, //开始时间
      JSSJ: this.$store.state.JSSJ, //结束时间
      type: 1,
      visible: false,

      datamate: {},
      loading: false,

      // 以下三个，为查询条件的三个地区选项。三选一。
      SFBH: "", // 省份编号。为空返回所有。
      SBH: "", // 市编号。为空返回所有。
      XBH: "", // 县/区编号。为空返回所有。
    };
  },

  components: {
    AppTree,
    BaseSelect,
    CenteredTable,
  },

  // mounted() {
  // 	var me = this;
  // 	me.HJJC_STATIONLIST_TRANSMISSION_LIST();
  // },

  methods: {
    moment,

    onChangeVal(e) {
      this.JZMC = e.target.value;
    },

    //查询
    onSearch() {
      this.current = 1;
      // this.EnterpriseBH = '';
      this.type = 1;
      this.HJJC_STATIONLIST_TRANSMISSION_LIST();
    },

    //导出
    onExport() {
      let me = this;
      // let ID = me.$store.state.loggedIn.CKM + ',,,,' + this.JZMC + ',,' + this.KSSJ + ',' + this.JZMC
      let ID = `${me.$store.state.loggedIn.CKM},${this.SFBH},${this.SBH},${this.XBH},${this.JZMC},,${this.KSSJ},${this.JSSJ},${this.EnterpriseBH}`;

      let HTTPURL = this.$store.state.HTTPURL;
      console.log(HTTPURL);

      window.open(
        "" +
          HTTPURL +
          "/data/RPT_GetPrintExcelFile.aspx?Module=SP&UID=admin&PID=&Template=104C4B0D23F6410B91A2764DHJJCCSLB&ID=" +
          ID +
          ""
      );
    },

    /**
     * 树点击  的点击事件。
     */
    onSelect(item, parent, state) {
      console.log(item);
      // console.log(parent);
      // console.log(state);
      // this.KSSJ = '';
      // this.JSSJ = '';
      let me = this;

      if (state == 1) {
        // this.HJJC_STATIONLIST_TRANSMISSION_LIST();
      } else {
        // if (item.Lx == 5) {
        // 	this.current = 1;
        // 	this.EnterpriseBH = item.id;
        // 	this.type = 2;
        // 	this.HJJC_STATIONLIST_TRANSMISSION_LIST();
        // }

        // SFBH:'',  // 省份编号。为空返回所有。
        // SBH:'',  // 市编号。为空返回所有。
        // XBH:'',  // 县/区编号。为空返回所有。
        switch (item.Lx) {
          case 1:
          case "1": // 全国
            me.SFBH = "";
            me.SBH = "";
            me.XBH = "";
            me.EnterpriseBH = "";
            break;
          case 2:
          case "2": // 省
            me.SFBH = item.id;
            me.SBH = "";
            me.XBH = "";
            me.EnterpriseBH = "";
            break;
          case 3:
          case "3": // 市
            me.SFBH = "";
            me.SBH = item.id;
            me.XBH = "";
            me.EnterpriseBH = "";
            break;
          case 4:
          case "4":
            me.SFBH = "";
            me.SBH = "";
            me.XBH = item.id;
            me.EnterpriseBH = "";
            break;
          case 5:
          case "5":
            me.SFBH = "";
            me.SBH = "";
            me.XBH = item.parentId;
            me.current = 1;
            me.EnterpriseBH = item.id;
            me.type = 2;

            break;
        }
        me.HJJC_STATIONLIST_TRANSMISSION_LIST();
      }
    },

    //选择日期
    onChangeDate(date, dateString) {
      console.log(date, dateString);
      this.KSSJ = dateString[0];
      this.JSSJ = dateString[1];
    },

    //分页
    onChange(pageNumber) {
      this.current = pageNumber;
      this.HJJC_STATIONLIST_TRANSMISSION_LIST();
    },

    /**
     * table 分页查询。
     */
    HJJC_STATIONLIST_TRANSMISSION_LIST() {
      var me = this;
      var data = {};
      //点击按钮完成搜索
      if (this.type == 1) {
        data = {
          CKM: me.$store.state.loggedIn.CKM,
          SFBH: me.SFBH,
          SBH: me.SBH,
          XBH: me.XBH,
          JZMC: me.JZMC,
          SXZM: "",
          KSSJ: me.KSSJ,
          JSSJ: me.JSSJ,
          EnterpriseBH: me.EnterpriseBH,
          start: (me.current - 1) * me.pageSize,
          limit: me.pageSize,
        };
      } else if (this.type == 2) {
        //点击树搜索
        data = {
          CKM: me.$store.state.loggedIn.CKM,
          SFBH: me.SFBH,
          SBH: me.SBH,
          XBH: me.XBH,
          JZMC: "",
          SXZM: "",
          KSSJ: "",
          JSSJ: "",
          EnterpriseBH: me.EnterpriseBH,
          start: (me.current - 1) * me.pageSize,
          limit: me.pageSize,
        };
      }

      this.loading = true;
      http({
        url: "/data/q30/HJJC_STATIONLIST_TRANSMISSION_LIST.aspx",
        data: data,
        success(obj) {
          console.log(obj);
          me.loading = false;
          me.data = obj.Data;

          if (obj.Data1 && obj.Data1.length > 0) {
            me.total = obj.Data1[0].ROWSCOUNT;
          }
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },

    // methods中定义方法
    customRow(record, index) {
      var me = this;
      return {
        on: {
          // 鼠标单击行
          click: (event) => {
            // console.log(record);
            // console.log(index);
            me.visible = true;
            me.datamate = JSON.parse(JSON.stringify(record));
            me.datamate.EnterpriseallMC =
              me.datamate.EnterpriseMC + "小时数据缺失情况";
            me.datamate.KSSJ = me.KSSJ;
            me.datamate.JSSJ = me.JSSJ;
            // me.$message.info("正在加载...", 0.001);
          },
        },
      };
    },

    hideModal() {
      this.visible = false;
    },
  },

  destoryed() {
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
#transmission-rate {
  font-family: PingFangSC-Medium, PingFang SC;
  height: 100%;

  .health {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
    height: 100%;

    .content {
      flex: 1;
      width: 0;
      background-color: #ffffff;
      padding: 20px 20px 0;

      h3 {
        text-align: center;
        font-size: 18px;
        height: 25px;
        line-height: 25px;
        margin-bottom: 24px;
      }

      .search-shell {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
        }
      }

      .search-btn {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .select-all {
          display: flex;
          align-items: center;
          margin-right: 20px;

          .label {
            width: 112px;
            font-size: 14px;
            color: #3f3f3f;
            line-height: 20px;
          }
        }

        .abnormal-btn {
          color: #ffffff;
          // font-size: 16px;
          background-color: #1876ff;
          // width: 72px;
          margin-right: 20px;
          // line-height: 30px;
        }
      }

      .table-shell {
        width: 100%;
        height: calc(100% - 145px);
        display: flex;
        flex-direction: column;

        @import "../../assets/css/AppHome.scss";

        &::v-deep .ant-table-thead {
          background: #ffffff;
        }

        .ant-table-wrapper {
          width: 100%;
          margin-bottom: 10px;
          flex: 1;
        }

        .all-total {
          padding: 6px 0 0;
          margin: 0 10px;
          color: rgba(0, 0, 0, 0.65);
          font-size: 14px;
        }
      }
    }
  }
}
</style>
