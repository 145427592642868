<template>
  <div class="CenteredTable">
    <div class="table-shell">
      <div style="display: flex">
        <div style="flex: 1"></div>
        <a-button
          style="margin-bottom: 10px; margin-right: 10px"
          @click="onAllBuChuan"
          type="primary"
        >
          补传
        </a-button>
        <a-button
          style="margin-bottom: 10px"
          @click="onAllRemarks"
          type="primary"
        >
          备注
        </a-button>
      </div>
      <a-table
        style="padding-bottom: 10px"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :scroll="{ y: 250 }"
        :columns="columns"
        size="small"
        :row-key="
          (r, i) => {
            i.toString();
          }
        "
        :data-source="data"
        bordered
        :pagination="false"
      >
        <template slot="operation" slot-scope="text, record">
          <a-button
            @click="onRepair(record)"
            size="small"
            style="margin-right: 10px"
            type="link"
          >
            补传
          </a-button>
          <a-button @click="onRemarks(record)" size="small" type="link">
            备注
          </a-button>
        </template>
        <span slot="FQ" slot-scope="FQ, record" @click="onFQClick(record)">
          <span>{{ FQ }} {{ record.HOUR }}</span>
        </span>
      </a-table>
    </div>
    <div style="display: flex">
      <div style="flex: 1"></div>
      <a-pagination
        show-quick-jumper
        :page-size="pageSize"
        :default-current="current"
        :total="total"
        :show-total="
          (total, range) => `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`
        "
        @change="onChange"
      />
    </div>
    <a-modal
      width="400px"
      centered
      v-model="visible"
      title="备注"
      ok-text="确认"
      cancel-text="取消"
      @ok="hideModal"
    >
      <a-textarea v-model="BZ" placeholder="请填写备注" :rows="6" />
    </a-modal>
  </div>
</template>

<script>
import { http, http2 } from "../utils/Http.js";
const columns = [
  {
    title: "基站名称",
    width: 160,
    dataIndex: "station_name",
    align: "center",
    ellipsis: true,
  },
  {
    title: "日期",
    dataIndex: "RQ",
    align: "center",
    scopedSlots: { customRender: "FQ" },
  },
  {
    title: "备注",
    dataIndex: "BZ",
    align: "center",
  },
  {
    title: "次数",
    dataIndex: "CS",
    align: "center",
  },
  {
    title: "操作",
    align: "center",
    width: 120,
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];

export default {
  props: {
    datamate: Object,
  },

  data() {
    return {
      data: [],
      columns,
      current: 1,
      pageSize: 20,
      total: 0,
      selectedRowKeys: [],
      visible: false,
      BZ: null,
      IDS: "",
    };
  },

  watch: {
    datamate(val) {
      console.log(val);
      this.data = [];
      this.selectedRowKeys=[];
      this.HJJC_DATA_REPAIRLIST();
    },
  },

  created() {
    this.HJJC_DATA_REPAIRLIST();
  },

  methods: {
    //补传
    onAllBuChuan: function () {
      let me = this;
      //me.$message.info("正在补传中...", 0);
      me.$message.destroy();
      let num = 0;
      me.btnvalue.forEach((element) => {
        // 上传  obj =>element
        let params = {
          CKM: me.$store.state.loggedIn.CKM,
          JKDBH: element.JKDBH,
          CmdCode: 2061,
          BeginTime: element.BeginTime,
          EndTime: element.EndTime,
        };
        me.commonRepair(params);
        num++;
        if (num == me.btnvalue.length) {
          //console.log("进入");
          //me.$message.success("补传完成", 2.5);
          me.$message.info("", 3);
          me.$message.info("加入补传队列完成", 3);
          // setTimeout(function () {
          //   //me.$message.info("加入补传队列完成", 3);
          // }, 0.000001);
          // me.$message.info("加入补传队列完成", 3);
          me.HJJC_DATA_REPAIRLIST();
        }
      });
      // me.$message
      //   .loading("正在补传中...", 0.01)
      //   .then(function () {
      //     me.$message.info("正在补传中...", 2.5);
      //   })
      //   .then(function () {
      //     let num = 0;
      //     me.btnvalue.forEach((element) => {
      //       // 上传  obj =>element
      //       console.log(element);
      //       let params = {
      //         CKM: me.$store.state.loggedIn.CKM,
      //         JKDBH: element.JKDBH,
      //         CmdCode: 2061,
      //         BeginTime: element.BeginTime,
      //         EndTime: element.EndTime,
      //       };
      //       me.commonRepair(params);
      //       num++;
      //       if (num == me.btnvalue.length) {
      //         //console.log("进入");
      //         //me.$message.success("补传完成", 2.5);
      //         me.HJJC_DATA_REPAIRLIST();
      //         me.$message.info("补传完成", 3.5);
      //       }
      //     });
      //   });
    },
    //分页
    onChange(pageNumber) {
      this.current = pageNumber;
      this.HJJC_DATA_REPAIRLIST();
    },

    onSelectChange(selectedRowKeys, value) {
      console.log("selectedRowKeys changed: ", value);
      this.selectedRowKeys = selectedRowKeys;
      this.btnvalue = value;
    },

    HJJC_DATA_REPAIRLIST() {
      var me = this;
      http({
        url: "/data/q30/HJJC_DATA_REPAIRLIST.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          station_bh: me.datamate.station_bh,
          EnterpriseMC: me.datamate.EnterpriseMC,
          KSSJ: me.datamate.KSSJ,
          JSSJ: me.datamate.JSSJ,
          start: (me.current - 1) * me.pageSize,
          limit: me.pageSize,
        },
        success(obj) {
          console.log(obj);
          me.data = obj.Data;
          me.total = obj.total;
          // if (obj.Data1 && obj.Data1.length > 0) {
          //   me.total = obj.Data1[0].ROWSCOUNT;
          // }
        },
        failure(obj) {},
        me,
      });
    },

    //通用补传
    commonRepair(params) {
      let me = this;
      http2({
        url: "/data/q30/HJJC_GetMinuteData_Asyn.aspx",
        data: params,
        success(obj) {
          console.log(obj);
        },
        failure(obj) {},
        me,
      });
    },

    //补传
    onRepair(event) {
      let me = this;
      let params = {
        CKM: me.$store.state.loggedIn.CKM,
        JKDBH: event.JKDBH,
        CmdCode: 2061,
        BeginTime: event.BeginTime,
        EndTime: event.EndTime,
      };
      http2({
        url: "/data/q30/HJJC_GetMinuteData_Asyn.aspx",
        data: params,
        success(obj) {
          console.log(obj);
          me.data = obj.Data;
          // if (obj.Data && obj.Data.length > 0) {
          // }

          if (obj.Data1 && obj.Data1.length > 0) {
            me.total = obj.Data1[0].ROWSCOUNT;
          }

          me.$message.destroy();
          me.$message.info("", 3);
          me.$message.info("加入补传队列完成", 5);
          this.HJJC_DATA_REPAIRLIST();
        },
        failure(obj) {
          me.$message.destroy();
          me.$message.info("", 3);
          me.$message.info(obj.errMsg, 3);
        },
        me,
      });
      return;
      me.$message
        .loading("正在补传中...", 0.01)
        .then(function () {
          me.$message.info("正在补传中...", 2.5);
        })
        .then(function () {
          let params1 = {
            CKM: me.$store.state.loggedIn.CKM,
            JKDBH: event.JKDBH,
            CmdCode: 2061,
            BeginTime: event.BeginTime,
            EndTime: event.EndTime,
          };
          http({
            url: "/data/q30/HJJC_GetMinuteData_Asyn.aspx",
            data: params1,
            success(obj) {
              console.log(obj);
              me.data = obj.Data;
              // if (obj.Data && obj.Data.length > 0) {
              // }

              if (obj.Data1 && obj.Data1.length > 0) {
                me.total = obj.Data1[0].ROWSCOUNT;
              }

              me.HJJC_DATA_REPAIRLIST();
              //me.$message.info("补传完成", 2.5);
            },
            failure(obj) {},
            me,
          });
        });
      return;
      let params2 = {
        CKM: me.$store.state.loggedIn.CKM,
        JKDBH: event.JKDBH,
        CmdCode: 2061,
        BeginTime: event.BeginTime,
        EndTime: event.EndTime,
      };
      http({
        url: "/data/q30/HJJC_GetMinuteData.aspx",
        data: params2,
        success(obj) {
          console.log(obj);
          me.data = obj.Data;
          me.$message.destroy();
          // if (obj.Data && obj.Data.length > 0) {
          // }

          if (obj.Data1 && obj.Data1.length > 0) {
            me.total = obj.Data1[0].ROWSCOUNT;
          }

          me.HJJC_DATA_REPAIRLIST();
        },
        failure(obj) {},
        me,
      });
    },

    //备注
    onRemarks(val) {
      this.visible = true;
      this.BZ='';
      this.IDS = val.ID;
    },

    //备注弹框确认
    hideModal(val) {
      this.visible = false;
      this.Form_Supple();
    },

    //全部备注
    onAllRemarks() {
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        this.visible = true;
        this.BZ='';
        let IDs=[];
        for(let i=0;i<this.btnvalue.length;i++){
            IDs.push(this.btnvalue[i].ID)
        }
        this.IDS = IDs.join(",");
      } else {
        this.$message.info("您还没有选中要备注的记录");
      }
    },

    Form_Supple() {
      var me = this;
      var data = {
        CKM: me.$store.state.loggedIn.CKM,
        BH:me.IDS,
        BZ: me.BZ,
      };
      http({
        url: "/data/q30/HJJC_MonitorValue_Form_Supple_BZ.aspx",
        data: data,
        success(obj) {
          console.log(obj);
          me.$message.info("备注完成");
          me.HJJC_DATA_REPAIRLIST();
          me.selectedRowKeys=[];
        },
        failure(obj) {},
        me,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.CenteredTable {
  height: 380px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .table-shell {
    flex: 1;
  }

  @import "../assets/css/AppHome.scss";

  &::v-deep .ant-table-thead {
    background: #ffffff;
  }
}
</style>
